import { VBtn } from 'vuetify/lib/components/VBtn';
import { VCard } from 'vuetify/lib/components/VCard';
import { VCardText } from 'vuetify/lib/components/VCard';
import { VDialog } from 'vuetify/lib/components/VDialog';
import { VIcon } from 'vuetify/lib/components/VIcon';
import { VSpacer } from 'vuetify/lib/components/VGrid';
import { VToolbar } from 'vuetify/lib/components/VToolbar';

var render = function render(){var _vm=this,_c=_vm._self._c;return _c(VDialog,{attrs:{"persistent":"","width":"793px"},on:{"click:outside":function($event){_vm.dialog = !_vm.dialog}},scopedSlots:_vm._u([{key:"activator",fn:function({ on, attrs }){return [_c(VBtn,{attrs:{"text":"","loading":_vm.loading},on:{"click":_vm.fetchCertificateFile}},[_c(VIcon,{attrs:{"color":"gray","left":"","small":""}},[_vm._v("mdi-eye")]),_vm._v(" "+_vm._s(_vm.$t('general.buttons.view'))+" "+_vm._s(_vm.$t('tokens.titles.certificate'))+" ")],1)]}}]),model:{value:(_vm.dialog),callback:function ($$v) {_vm.dialog=$$v},expression:"dialog"}},[(_vm.blob)?_c(VCard,{staticClass:"fill-height"},[_c(VToolbar,{staticClass:"toolbar__fixed px-4"},[_c(VBtn,{attrs:{"loading":_vm.loading,"variant":"elevated","color":"secondary","elevation":"0","border":"0"},on:{"click":_vm.downloadCertificate}},[_vm._v(" "+_vm._s(_vm.$t('general.buttons.download'))+" "),_c(VIcon,{attrs:{"color":"white","right":"","size":"24","small":""}},[_vm._v("mdi-download")])],1),_c(VSpacer),_c(VBtn,{attrs:{"color":"secondary","text":""},on:{"click":function($event){_vm.dialog = !_vm.dialog}}},[_c(VIcon,[_vm._v("mdi-close")])],1)],1),_c(VCardText,{staticClass:"mx-0 px-0"},[_c('vue-pdf-embed',{staticClass:"pt-12",attrs:{"source":_vm.blob}})],1)],1):_vm._e()],1)
}
var staticRenderFns = []

export { render, staticRenderFns }